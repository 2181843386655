import { createAction, props } from '@ngrx/store';
import { CategoryProd } from 'src/app/models/categoryProduct.model';
import { IAddonProductsGroup, IAddonsCombo, Product, ProductData } from 'src/app/models/product.model';
import { Filter } from '../../models/filter.model';

export const loadProducts = createAction(
  '[Product List API] Product list',
  props<{ filter: string, storeId: string, store: string, token?: string }>()
);

export const loadMinProducts = createAction(
  '[Product Min List API] Product Min list',
  props<{ filter: string, storeId: string, Limit: number, store: string }>()
);

export const loadProductsByCatId = createAction(
  '[Product List By Category ID API] Product list By Category ID',
  props<{ categoryId: string, storeId: string, store: string }>()
);

export const loadProductsByCatIdSuccess = createAction(
  '[Product List By Category ID API] Product list By Category ID Success',
  props<{ list: any[], filter: string, store: string }>()
);

export const loadProductsSuccess = createAction(
  '[Product List API] Product list loaded success',
  props<{ list: Product[], filter: string, store: string, buildingState?: boolean }>()
);

export const loadProductsFailure = createAction(
  '[Product List API] Product list loaded failure',
  props<{ error: string }>()
);


export const updateCart = createAction(
  '[Product List API] Update Cart Product list',
  props<{ filter: string, product: Product, isAdd: boolean, store: string, catId?: string , changeComboQuantity?: boolean}>()
);

export const updateProductFromSocket = createAction(
  '[Product List API] Update Product for Socket',
  props<{ response: any, catId?: string }>()
);

export const sortProducts = createAction(
  '[Product List API] Sort Product list',
  props<{ isAscending: boolean, filter: string, store: string }>()
);


export const setSelectedProduct = createAction(
  '[Product List API] Update Selected Product list',
  props<{ product: Product, forAddon?: boolean }>()
);

export const setSelectedProductC = createAction(
  '[Product List API] Update Selected Product copy list',
  props<{ product: Product, forAddon?: boolean, isAdd: boolean }>()
);

export const removeItemCart = createAction(
  '[Product List API] Remove Product list',
  props<{ product: Product, filter: string, store: string }>()
);

export const setStoreName = createAction(
  '[store API] store name',
  props<{ storeName: string }>()
);

export const resetCart = createAction(
  '[Product List API] reset cart Product list',
  props<{ filter: string, store: string, fromMenuPage?: boolean }>()
);

export const loadCategoriesSuccess = createAction(
  '[Category List API] Category list loaded success',
  props<{ list: CategoryProd[], store: string }>()
);


export const byPassCatProdLoad = createAction(
  '[Product List API] Load Category Products stop',
);

export const buildCategoryProd = createAction(
  '[Category Product API] Load Category Products',
  props<{ storeId?: string[], store: string, loginType?: string, accountId: string, token?: string }>()
);


export const buildCategoryProdError = createAction(
  '[Category Product API] Load Category Products Failure',
  props<{ error: string }>()
);

export const buildCategoryProdinState = createAction(
  '[Category Product API] Build Category Products State',
  props<{ list: CategoryProd[], store: string }>()
);


export const loadFilters = createAction(
  '[Filter List API] Load Filter list',
  props<{ store: string, accountId: string, token?: string }>()
);

export const loadFiltersSuccess = createAction(
  '[Filter List API] Filter list loaded success',
  props<{ list: Filter[], store: string }>()
);

export const loadFiltersFailure = createAction(
  '[Filter List API] Filter list loaded failure',
  props<{ error: string }>()
);

export const buildProductState = createAction(
  '[Filter List API] Builiding Product State',
  props<{ data: Filter[], store: string }>()
);

export const clearCategoryState = createAction(
  '[Category List API] Clearing category State',
  props<{ store: string }>()
);

export const loadAddons = createAction(
  '[Addon Asset API] loading addon items',
  props<{ store: string, settingUpdate?: boolean }>()
);

export const clearAddons = createAction(
  '[Addon Asset API] clearing addon data in state',
  props<{ store: string }>()
);

export const setAddons = createAction(
  '[Addon Asset API] setting addon data in state',
  props<{  store: string }>()
);

export const storeAddOnsDataInState = createAction(
  '[Addon Asset API] store AddOns Data In State',
  props<{ addonData: IAddonProductsGroup[] }>()
);

export const addAddonsToCart = createAction(
  '[Addon Add] adding addons and product into cart',
  props<{ productId: string, store: string, selectedP: Product, editMode?: boolean, lastComboId?: string }>()
);

export const addGroupedAddonsToCart = createAction(
  '[Addon Add] adding grouped addons and product into cart',
  props<{ productId: string, store: string, selectedP: Product, editMode?: boolean,groupedAddOnProds:IAddonProductsGroup[],modfiedAddOnCombos:IAddonsCombo[],comboPrice:number,comboName:string }>()
);

export const selectAddons = createAction(
  '[Addon Add] select different addons on addonWindow',
  props<{ addonId: string, isAdd: boolean, selectedProduct?: Product, selectedProdc?: Product }>()
);

export const loadPopularProducts = createAction(
  '[popular product API] Load Popular Products',
  props<{ store: string }>()
);

export const loadPopularProductsSuccess = createAction(
  '[popular product API] Load Popular Products Success',
  props<{ data: ProductData, store: string }>()
);

export const loadPopularProductsFailure = createAction(
  '[popular product API] popular api fails to fetch data',
  props<{ store: string }>()

)

export const downloadSalesReport = createAction(
  '[popular product API] download sales report',
  props<{ startTime: number,endTime: number ,terminalId:string,storeId:string}>()
)