import { createAction, props } from "@ngrx/store";
import { RzpImg } from "src/app/models/asset.modal";
// import { IPrinterStatus } from 'src/app/models/config.model';
import { PluginManagerRes, Product } from "src/app/models/product.model";

export const discardOrder = createAction(
  "[Discard Order API]  Discard order",
  props<{
    screen: string;
  }>()
);

export const discardOrderSuccess = createAction(
  "[Discard Order API Success] Order discard success",
  props<{
    checkoutData: any;
  }>()
);

export const createCheckout = createAction(
  "[Create CheckoutData API] Create Checkout",
  props<{
    items: any;
    routeAfterSuccess?: boolean;
    dineType: string;
    taxData?: ITaxData;
    orderDetails?: any;
  }>()
);

export const executeTaxCalculation = createAction(
  "[Create CheckoutData API] execute tax calculation",
  props<{
    items: any;
    routeAfterSuccess?: boolean;
    dineType: string;
    orderId?: string;
  }>()
);

export const loadQR = createAction(
  "[Checkout QR API] Load QR Checkout",
  props<{
    orderId: string;
  }>()
);

export const loadQRSuccess = createAction(
  "[Checkout QR API] Load QR Checkout Success",
  props<{
    QRUrl: string;
    base64: string;
  }>()
);

export const initiatePayment = createAction(
  "[Checkout QR API] Initiate Payment",
  props<{
    orderId: string;
  }>()
);

export const initiatePaymentSuccess = createAction(
  "[Checkout QR API] Initiate Payment Success",
  props<{
    paymentId: string;
  }>()
);

export const createCheckoutSuccess = createAction(
  "[Update CheckoutData API] Update Checkout",
  props<{
    checkoutData: any;
  }>()
);

export const updateCheckoutData = createAction(
  "[CheckoutDataUpdate API Success] checkoutData update",
  props<{
    items: any;
    orderId: string;
    routeAfterSuccess?: boolean;
    dineType: string;
    taxData?: ITaxData;
    orderDetails?: any;
  }>()
);

export const updateCheckoutDataSuccess = createAction(
  "[CheckoutDataUpdate API Success] checkoutData update success",
  props<{
    checkoutData: any;
  }>()
);

export const quantityCheckAndPay = createAction(
  "[Quantity API] quantity check and pay",
  props<{
    items: any[];
    orderId: string;
    bypass: boolean;
  }>()
);

export const quantityCheck = createAction(
  "[QuantityCheck API] quantity check",
  props<{
    items: any[];
    showCheckout: boolean;
    isCheckout: boolean;
    orderId?: string;
    product?: Product;
    filter: string;
    store: string;
    byPass?: boolean;
    routeAfterSuccess?: boolean;
    catId?: string;
    fromMenuPage?: boolean;
    dineType?: string;
  }>()
);

export const quantityCheckSuccess = createAction(
  "[QuantityCheck API Success] quantity check success",
  props<{
    data: any;
  }>()
);

export const toggleCheckout = createAction(
  "[Checkout API ] close checkout Data"
);

export const showCheckoutLoader = createAction(
  "[Checkout API ] show checkout loader"
);

export const showCheckout = createAction("[Checkout API ] show checkout");

export const closeModal = createAction("[Checkout API ] close modal");

export const showQRLoader = createAction("[Checkout API ] show qr loader");

export const destroyQR = createAction("[Checkout API ] destroy qr");

export const hideCheckoutLoader = createAction(
  "[Checkout API ] hide checkout loader"
);

export const showOutOfStock = createAction(
  "[Checkout API ] show Out of stock",
  props<{
    msg: string;
  }>()
);

export const showQuantityLoader = createAction(
  "[Checkout API ] show quantity loader",
  props<{
    id: any;
  }>()
);

export const failure = createAction(
  "[QuantityCheck API Failure] quantity check fail",
  props<{
    error: string;
  }>()
);

export const openPopup = createAction(
  "[Open Popup Modal] popup modal",
  props<{
    popupName: string;
    decreasing?: boolean;
    comboId?: string;
  }>()
);

export const paymentMode = createAction(
  "[Change Payment Mode] payment Mode",
  props<{
    paymentOn: any;
  }>()
);

export const onlinePaymentStatus = createAction(
  "[Check Razorpay Status] online payment Mode",
  props<{
    onlinePayment: boolean;
  }>()
);

export const onlinePaymentQRLoadSuccess = createAction(
  "[Check Razorpay Status] online payment qr loaded om img tag"
);

export const clearCheckout = createAction(
  "[Clear Checkout] clearing checkout data"
);

export const navigateToHome = createAction("[Route to home] Navigate to Home");

export const getRazorpayAccountKey = createAction(
  "[Razorpay Plugin Manager] Plugin Manager Call"
);

export const getRazorpayAccountKeySuccess = createAction(
  "[Razorpay Plugin Manager] Plugin Manager Call success",
  props<{
    res: PluginManagerRes;
  }>()
);

export const getRazorpayImg = createAction("[Razorpay Img api] Get Rzp Img");

export const getRazorpayImgSuccess = createAction(
  "[Razorpay Img api] Get Rzp Img Success",
  props<{
    img: RzpImg;
  }>()
);

export const updatePaymentStatus = createAction(
  "[Payment Status] Updating online payment status"
);

export const loadStatusQr = createAction(
  "[Payment Status qr api] load qr status qr",
  props<{
    orderId: string;
  }>()
);

export const updateStatusQr = createAction(
  "[Payment Status qr api] load qr status qr success",
  props<{
    img: string;
  }>()
);

export const productUpdatedBySocketInventory = createAction(
  "[Product Update] inventory updated by Socket",
  props<{isUpdated: boolean}>()
);
// export const setPrinterStatus = createAction(
//   '[Kiosk Store] set printer status',
//   props<{ status?: Record<string, IStatus>, event?: string }>()
// )

// export const updatePrinterStatus = createAction(
//   '[Kiosk Store ] update printer status',
//   props<{ status?: IPrinterStatus }>()
// )

export const setPaymentMode = createAction(
  "[Kiosk Store] set payment mode",
  props<{ paymentMode: string }>()
);

interface IItem {
  name: string;
  price: number;
  quantity: number;
  parcelCharge: number;
  parcelChargeTotal: number;
  parcelChargeTax: number;
  _id: string;
  itemsPriceAfterTax: number;
  itemsPriceBeforeTax: number;
  unitPriceAfterTax: number;
  unitPriceBeforeTax: number;
}

interface ITax {
  name: string;
  amt: number;
  value: number;
}

interface IParcelCharge {
  charge: number;
  total: number;
  tax: number;
}

export interface ITaxData {
  gateway: string;
  paymentModes: string[];
  kioskFront: KioskFront;
  items: IItem[];
  tax: ITax[];
  total: number;
  parcelCharge: IParcelCharge;
  subTotal: number;
  totalTaxAmt: number;
}

export interface KioskFront {
  inAppPayment: boolean;
  sendPaymentLink: boolean;
  payInStore: boolean;
  tryDifferentMethods: boolean;
}
