import { AppState } from "../app.state";

export const selectCheckoutData = (state: AppState) => state.Checkout.checkoutData;

export const selectIsCheckout = (state: AppState) => state.Checkout.isCheckout;

export const selectCheckoutLoader = (state: AppState) => state.Checkout.checkoutLoader;

export const selectCheckoutBtnLoader = (state: AppState) => state.Checkout.checkoutBtnLoader;

export const selectIsOutOfStock = (state: AppState) => state.Checkout.isOutofStock;

export const selectOutOfStockMsg = (state: AppState) => state.Checkout.quantityCheckMsg;

export const selectQRLoader = (state: AppState) => state.Checkout.QRLoader;

export const selectQRUrl = (state: AppState) => state.Checkout.QRUrl;

export const selectPaymentId = (state: AppState) => state.Checkout.paymentId;

export const selectQuantityId = (state: AppState) => state.Checkout.quantityId;

export const popupNameSelector = (state: AppState) => state.Checkout.popupName;

export const paymentModeSelector = (state: AppState) => state.Checkout.paymentMode;

export const onlinePaymentStatusSelector = (state: AppState) => state.Checkout.onlinePayment;

export const rzpAccountValue = (state: AppState) => state?.Checkout?.rzpAccountData;

export const getRzpImg = (state: AppState) => state?.Checkout?.rzpImg;

export const getOnlinePaymentStatus = (state: AppState) => state?.Checkout?.paymentStatus;

export const getOrderStatusQr = (state: AppState) => state?.Checkout?.statusQr;

export const isUpdateCallInFlight = (state: AppState) => state?.Checkout?.calculatingTax;

export const isproductUpdatedBySocketInventory = (state: AppState) => state?.Checkout?.isproductUpdatedBySocketInventory
